<template>
  <div class="main">
    <!-- 查看档案弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="checkArchives"
      title="客户档案"
      height="50vh"
    >
      <div class="note_box">
        <el-scrollbar height="500px">
          <div
            class="note_content"
            v-for="(item, index) in archivesList"
            :key="index"
          >
            <div style="width: 80%">
              <div class="time">时间：{{ item.recordTime }}</div>
              <div class="note">描述：{{ item.title }}</div>
              <div class="note">档案：{{ item.archivesDescribe }}</div>
              <el-image
                v-if="item.img"
                style="width: 300px;height:auto"
                :src="item.img"
                :preview-src-list="[item.img]"
              >
              </el-image>
              <!-- <div><img :src="item.img" /></div> -->
            </div>
            <div>
              <el-button type="success" plain size="small" @click="daochu(index)"
                >导出</el-button
              >
              <el-button
                type="danger"
                plain
                size="small"
                @click="delArchives(index)"
                >删除</el-button
              >
            </div>
          </div>
        </el-scrollbar>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="addArchives = true">
            添加档案
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 添加档案弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="addArchives"
      title="添加客户档案"
      width="40%"
    >
      <div class="archives_content">
        <div class="archives_box">
          <span>描述</span
          ><el-input v-model="inputArchives" style="width: 90%" />
        </div>
        <div class="archives_box">
          <span>档案</span>
          <el-input
            v-model="textareaArichives"
            :rows="4"
            type="textarea"
            placeholder="请输入档案内容"
            style="width: 90%"
          />
        </div>
        <div class="archives_up">
          <span>上传图片</span>
          <upload @uploadimgzj="imgUrl" />
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addArchives = false">取消</el-button>
          <el-button type="primary" @click="saveArchives()"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import upload from "@/components/uploadImg/upload";
import {
  getRecodeList,
  addRecode,
  delRecode,
  exportRecord,
} from "../../../../api/crm";
export default {
  name: "archivesDialog",
  components: {
    upload,
  },
  props: {},
  data() {
    return {
      img: "",
      checkArchives: false,
      addArchives: false,
      archivesForm: {
        customerId: "",
        recordType: "2",
      },
      archivesList: [],
      inputArchives: "",
      textareaArichives: "",
    };
  },
  methods: {
    imgUrl(val) {
      this.img = val;
    },
    //获取用户档案
    checkArchivesList(row) {
      if (row) {
        this.archivesForm.customerId = row.id;
      }
      this.checkArchives = true;
      getRecodeList(this.archivesForm)
        .then((res) => {
          if (res.data.msg == "success") {
            this.archivesList = res.data.data.list;
            console.log(this.archivesList);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //新增档案
    saveArchives() {
      addRecode({
        customerId: this.archivesForm.customerId,
        recordType: "2",
        title: this.inputArchives,
        archivesDescribe: this.textareaArichives,
        img: this.img,
      })
        .then((res) => {
          if (res.data.msg == "success") {
            this.addArchives = false;
            this.$message({
              type: "success",
              message: "添加成功",
            });
            this.checkArchivesList();
            this.inputArchives = "";
            this.textareaArichives = "";
            this.img = "";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //导出档案
    daochu(index) {
      exportRecord({ recordId: this.archivesList[index].id }).then((res) => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
    //删除档案
    delArchives(index) {
      this.$confirm("确定删除档案吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delRecode({
          recordId: this.archivesList[index].recordId,
          id: this.archivesList[index].id,
        })
          .then((res) => {
            if (res.data.msg == "success") {
              this.$message({
                type: "success",
                message: "删除成功",
              });
            }
            this.checkArchivesList();
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style scoped lang="less">
.note_box {
  height: 500px;
}
.note_content {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: flex-end;
  padding-bottom: 20px;
}
.note_content img {
  width: 300px;
  margin-top: 5px;
}
.time {
  font-size: 18px;
}
.note {
  font-size: 14px;
  margin-top: 5px;
}
.archives_content {
  width: 90%;
  height: 500px;
  margin: 0 auto;
}
.archives_box {
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}
.archives_up {
  width: 90%;
  display: flex;
}
</style>

<template>
  <div class="home">
    <!-- 筛选 -->
    <div class="top_select">
      <div>
        <el-input
          placeholder="请输入公司名称"
          v-model="this.listQuery.customerName"
          style="width: 200px; margin-right: 5px"
          size="small"
          @keyup.enter="getList"
        />
        <el-button type="primary" @click="getList()" size="small" icon="Search"
          >搜索</el-button
        >
        <selectuser
          code="coopCustom"
          v-model:userId="listQuery.userId"
          @change="getList()"
        ></selectuser>
      </div>
      <div>
        <el-button
          type="primary"
          size="small"
          plain
          @click="daoruFormVisible = true"
          >导入</el-button
        >
        <el-button type="primary" size="small" @click="addCustomer()"
          >新增合作客户</el-button
        >

        <!-- <el-button type="success" size="small" plain>导出</el-button> -->
      </div>
    </div>
    <!-- 客户列表 -->
    <el-table
      :data="customerList"
      style="width: 100%"
      border
      height="680px"
      @selection-change="handleSelectCustomer"
      v-loading="listLoading"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="55" align="center" fixed />
      <el-table-column
        type="index"
        label="序号"
        width="55"
        align="center"
        fixed
      />
      <el-table-column
        prop="customerName"
        label="公司名称"
        align="center"
        fixed
        show-overflow-tooltip="false"
      />
      <el-table-column
        prop="linkman"
        label="联系人"
        width="180"
        align="center"
      />
      <el-table-column
        prop="linkmanTel"
        label="联系人电话"
        width="180"
        align="center"
      />
      <el-table-column
        prop="disCodes"
        label="业务负责人"
        width="180"
        align="center"
      />
      <el-table-column label="操作" align="center" fixed="right" width="450">
        <template #default="scope">
          <el-button
            type="primary"
            size="small"
            @click="getRecordList(scope.row)"
            >小记</el-button
          >
          <el-button
            type="success"
            plain
            size="small"
            @click="moveContract(scope.row)"
            >合同</el-button
          >
          <el-button
            type="warning"
            plain
            size="small"
            @click="getArchivesList(scope.row)"
            >档案</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="updateCustomer(scope.row)"
            >修改</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="handleTermination(scope.row)"
            >解约</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="foot">
      <div>
        <el-button
          type="primary"
          size="small"
          :disabled="this.sels.length === 0"
          @click="allotFormVisible = true"
          icon="Share"
          >批量分配</el-button
        >
        <el-button
          type="danger"
          size="small"
          :disabled="this.sels.length === 0"
          @click="clickBreak"
          icon="Switch"
          >批量解约</el-button
        >
      </div>
      <div class="pagination" style="margin-top: 0">
        <qzf-pagination
          v-show="total > 0"
          :total="total"
          v-model:page="listQuery.page"
          v-model:limit="listQuery.limit"
          @pagination="getList()"
        />
      </div>
    </div>

    <!-- 新增编辑的弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="coopCustomerVisible"
      width="40%"
      :title="textMap[dialogStatus]"
    >
      <!-- 新增修改客户表单 -->
      <div class="content">
        <el-form
          ref="customerForm"
          :model="customerRuleForm"
          :rules="rules"
          label-width="120px"
          status-icon
        >
          <el-form-item label="公司名称" prop="customerName">
            <el-input v-model="customerRuleForm.customerName" />
          </el-form-item>
          <el-form-item label="业务负责人" prop="userId">
            <business
              code="coopCustom"
              v-model:userId="customerRuleForm.userId"
            ></business>
          </el-form-item>
          <el-form-item label="联系人" prop="linkman">
            <el-input v-model="customerRuleForm.linkman" />
          </el-form-item>
          <el-form-item label="电话" prop="linkmanTel">
            <el-input v-model="customerRuleForm.linkmanTel" />
          </el-form-item>
          <el-form-item label="地址" prop="address">
            <el-input v-model="customerRuleForm.address" />
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="customerRuleForm.remark"
              type="textarea"
              show-overflow-tooltip="false"
            />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="coopCustomerVisible = false">取消</el-button>
          <el-button
            type="primary"
            @click="dialogStatus === 'create' ? createData() : updateData()"
          >
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 批量分配弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="allotFormVisible"
      title="批量分配"
      height="50vh"
    >
      <span>选择跟进人：</span
      ><business code="coopCustom" v-model:userId="value"></business>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="allotFormVisible = false">取消</el-button>
          <el-button type="primary" @click="saveAllot()"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 解约原因 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="breakFormVisible"
      title="解约原因"
      height="50vh"
      :before-close="handleClose"
    >
      <el-form
        :model="breakForm"
        :rules="rulesBreak"
        ref="ruleFormBreak"
        label-width="100px"
      >
        <el-form-item label="解约原因：" prop="terminationReason">
          <el-input
            v-model="breakForm.terminationReason"
            :rows="5"
            type="textarea"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="breakFormVisible = false">取消</el-button>
          <el-button type="primary" @click="moveTermination()">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 导入模板 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="daoruFormVisible"
      title="导入"
      height="50vh"
    >
      <el-form size="small" label-position="right" label-width="5px">
        <el-form-item label="">
          <el-input
            style="width: 60%; margin-right: 10px"
            v-model="path"
          ></el-input>
          <input
            size="small"
            ref="excel-upload-input"
            class="excel-upload-input"
            style="display: none"
            type="file"
            accept=".xlsx, .xls, .xml"
            @change="handleClick"
          />
          <el-button type="primary" @click="handleUpload()" size="small"
            >请选择文件</el-button
          >
        </el-form-item>
      </el-form>
      <div>
        <a href="javascript:;" @click="loadForm()">下载模板</a>
        <div style="text-align: right">
          <el-button size="small" type="primary" @click="uploadExcel"
            >上传</el-button
          >
        </div>
      </div>
    </el-dialog>
    <noteDialog ref="noteDialog" />
    <archivesDialog ref="archivesDialog" />
  </div>
</template>

<script>
import noteDialog from "./components/noteDialog.vue";
import archivesDialog from "./components/archivesDialog.vue";
import business from "./components/business.vue";
import {
  addCustomer,
  getCustomerList,
  updateCustomer,
  saveStatus,
  importCustomer,
} from "../../../api/crm";
export default {
  name: "coopCustom",
  components: {
    noteDialog,
    archivesDialog,
    business,
  },
  data() {
    let validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号！"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号！"));
      } else {
        return callback();
      }
    };
    return {
      listQuery: {
        page: 1,
        limit: 20,
        customerType: "2",
        customerName: null,
      },
      allotFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      listLoading: false,
      daoruFormVisible: false,
      rawFile: "",
      path: "",
      breakFormVisible: false,
      coopCustomerVisible: false,
      customerRuleForm: {
        id: "",
        customerName: "",
        userId: [],
        linkman: "",
        linkmanTel: "",
        address: "",
        remark: "",
        customerType: "2",
      },
      breakForm: {
        id: "",
        terminationReason: "",
        customerType: "3",
      },
      rules: {
        customerName: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        userId: [
          { required: true, message: "请选择业务负责人", trigger: "change" },
        ],
        linkman: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        linkmanTel: [
          { required: true, validator: validateMobile, trigger: "blur" },
        ],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
      },
      rulesBreak: {
        terminationReason: [
          {
            required: true,
            message: "请输入解约原因",
            trigger: "blur",
          },
        ],
      },
      keywords: "",
      customerList: [],
      total: "",
      sels: [],
      value: [],
    };
  },
  mounted() {
    this.getList();
    this.initBus();
  },
  methods: {
    initBus() {
      this.$bus.off("coopUpdate");
      this.$bus.on("coopUpdate", (val) => {
        this.getList();
      });
    },
    //获取合作客户列表
    getList() {
      this.listLoading = true;
      getCustomerList(this.listQuery).then((res) => {
        this.listLoading = false;
        if (res.data.msg == "success") {
          this.customerList = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    //导入合同
    loadForm() {
      window.open(
        "https://file.listensoft.net/mnt/v2/other/合作客户导入.xlsx" +
          "?" +
          new Date().getTime()
      );
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    uploadExcel() {
      this.isLoading = true;
      const param = new FormData(); // 创建form对象
      param.append("file", this.rawFile, this.rawFile.name);
      importCustomer(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("导入成功");
          this.getList();
          this.$bus.emit("contractUpdate");
          this.daoruFormVisible = false;
          this.path = "";
        }
      });
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "请不要上传大于1M的文件。",
        type: "warning",
      });
      return false;
    },
    //小计
    getRecordList(row) {
      this.$refs.noteDialog.getNoteList(row);
    },
    //档案
    getArchivesList(row) {
      this.$refs.archivesDialog.checkArchivesList(row);
    },
    //新增客户
    addCustomer() {
      this.resetForm();
      (this.dialogStatus = "create"), (this.coopCustomerVisible = true);
      this.$nextTick(() => {
        this.$refs["customerForm"].clearValidate();
      });
    },
    //修改客户
    updateCustomer(row) {
      this.customerRuleForm = Object.assign({}, row);
      this.dialogStatus = "update";
      this.coopCustomerVisible = true;
      this.$nextTick(() => {
        this.$refs["customerForm"].clearValidate();
      });
    },
    createData() {
      this.$refs["customerForm"].validate((valid) => {
        if (valid) {
          addCustomer(this.customerRuleForm).then((res) => {
            if (res.data.msg == "success") {
              this.$bus.emit("contractUpdate");
              this.getList();
              this.coopCustomerVisible = false;
              this.$qzfMessage("创建成功");
            }
          });
        }
      });
    },
    updateData() {
      this.$refs["customerForm"].validate((valid) => {
        if (valid) {
          updateCustomer(this.customerRuleForm).then((res) => {
            if (res.data.msg == "success") {
              this.getList();
              this.coopCustomerVisible = false;
              this.$qzfMessage("修改成功");
            }
          });
        }
      });
    },
    resetForm() {
      this.customerRuleForm = {
        customerName: "",
        userId: [],
        linkman: "",
        linkmanTel: "",
        address: "",
        remark: "",
        customerType: "2",
      };
    },
    handleSelectCustomer(sels) {
      this.sels = sels;
    },
    //解约
    handleTermination(row) {
      this.resetBreakForm();
      this.breakForm.id = row.id;
      this.breakFormVisible = true;
    },
    clickBreak() {
      this.resetBreakForm();
      this.breakFormVisible = true;
    },
    resetBreakForm() {
      this.breakForm = {
        id: "",
        terminationReason: "",
        customerType: "3",
      };
    },
    moveTermination() {
      this.$refs["ruleFormBreak"].validate((valid) => {
        if (valid) {
          if (this.breakForm.id == "") {
            let ids = [];
            this.sels.map((item) => {
              ids.push({
                id: item.id,
                customerType: "3",
                terminationReason: this.breakForm.terminationReason,
              });
            });
            saveStatus(ids).then((res) => {
              if (res.data.msg == "success") {
                this.$qzfMessage("修改成功");
                this.$bus.emit("termUpdate");
                this.$bus.emit("contractUpdate");
                this.getList();
                this.breakFormVisible = false;
                this.breakForm.terminationReason = "";
              }
            });
          } else {
            updateCustomer(this.breakForm).then((res) => {
              if (res.data.msg == "success") {
                this.$qzfMessage("解约成功");
                this.$bus.emit("termUpdate");
                this.$bus.emit("contractUpdate");
                this.getList();
                this.breakFormVisible = false;
                this.resetBreakForm();
              }
            });
          }
        }
      });
    },
    //批量分配
    saveAllot() {
      if (this.value.length == 0) {
        this.$qzfMessage("请选择负责人", 1);
        return;
      }
      let params = [];
      this.sels.map((item) => {
        params.push({
          id: item.id,
          userId: this.value,
          customerType: item.customerType,
          typeName: "1",
        });
      });
      saveStatus(params).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("分配成功");
          this.getList();
          this.allotFormVisible = false;
          this.value = [];
        }
      });
    },
    //跳转传参
    moveContract(row) {
      this.$store.dispatch("commons/setParam", { customerId: row.id });
      this.$store.dispatch("tagsView/delCachedViewByName", "contractList");
      this.$router.push({
        name: "contractList",
        params: { customerId: row.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top_select {
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
}
.note_box {
  height: 500px;
}
.note_content {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: flex-end;
  padding-bottom: 20px;
}
.note_content img {
  width: 300px;
  margin-top: 5px;
}
.el-form-item {
  margin-bottom: 22px !important;
}
.time {
  font-size: 18px;
}
.note {
  font-size: 14px;
  margin-top: 5px;
}
.foot {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
</style>
